<script setup lang="ts">
import { CmsPage, CmsSection } from "@shopware-pwa/types";
import { useNavigationContext } from "@shopware-pwa/composables-next";

/**
 * IMPORTANT: we removed dynamic renderer due to issues with server components and hydration
 *
 * Issue: https://github.com/nuxt/nuxt/issues/26434
 *
 * TODO: reenable dynamic renderer when issue is fixed
 *
 * see original: https://github.com/shopware/frontends/blob/main/packages/cms-base/components/public/cms/CmsPage.vue
 */

const props = defineProps<{
  content: CmsPage;
}>();

const { routeName } = useNavigationContext();
if (routeName.value === "frontend.navigation.page") {
  useListing();
}

const cmsSections = computed<CmsSection[]>(() => {
  return props.content?.sections || [];
});
</script>

<template>
  <template v-for="section in cmsSections" :key="`${section.pageId}-${section.position}`">
    <CmsSectionDefault :content="section" v-if="section.type === 'default'"/>
    <CmsSectionSidebar :content="section" v-else-if="section.type === 'sidebar'"/>
  </template>
</template>